import { createContext, FC } from 'react';

import { useLocalStorageValue } from '@react-hookz/web';

import { ApiSettings } from '@hultafors/shared/types';

import {
  addToCart,
  CART_KEY,
  generateFakeIdForProduct,
} from '@hultafors/solidgear/helpers';
import { SolidgearProduct } from '@hultafors/solidgear/types';

export interface CartContextValue {
  cart: SolidgearProduct[];
  addItemToCart(product: SolidgearProduct, amount: number): void;
  deleteItemFromCart(e: any, product: SolidgearProduct): void;
  deleteCart(): void;
}

const defaultValue: CartContextValue = {
  addItemToCart: () => {
    return;
  },
  cart: [],
  deleteCart: () => {
    return;
  },
  deleteItemFromCart: () => {
    return;
  },
};

export const CartContext = createContext<CartContextValue>(defaultValue);

export interface CartProviderProps {
  children?: React.ReactNode;
  settings: ApiSettings;
}

export const CartProvider: FC<CartProviderProps> = ({ children, settings }) => {
  const cart = useLocalStorageValue<SolidgearProduct[]>(
    `${CART_KEY}-${settings?.market || 'com'}`,
    { defaultValue: [], initializeWithValue: false },
  );

  const addItemToCart = (product: SolidgearProduct, amount: number) => {
    const result = addToCart(cart.value || [], product, amount);
    if (result) {
      cart.set(result);
    }
  };

  const deleteCartItem = (e: any, product: SolidgearProduct) => {
    e.preventDefault();

    let result: any[] = [];

    if (cart) {
      const fakeSKU = generateFakeIdForProduct(product);

      result = (cart.value || []).filter((item) => item.fakeSKU !== fakeSKU);
    }
    cart.set(result);
  };

  const deleteCart = () => {
    cart.set([]);
  };

  return (
    <CartContext.Provider
      value={{
        addItemToCart,
        cart: cart.value || [],
        deleteCart,
        deleteItemFromCart: deleteCartItem,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
