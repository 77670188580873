import { createContext } from 'react';

import { useLocalStorageValue } from '@react-hookz/web';

import { ApiSettings } from '@hultafors/shared/types';

import { toggleFavorite } from '@hultafors/solidgear/helpers';
import { SolidgearProduct } from '@hultafors/solidgear/types';

export interface FavoritesContextValue {
  favorites: SolidgearProduct[];
  toggleFavoriteItem(product: SolidgearProduct, isFavorite: boolean): void;
  deleteFavorites(): void;
  isProductFavorite(sku: string): boolean;
}

const defaultValue: FavoritesContextValue = {
  deleteFavorites: () => {
    return;
  },
  favorites: [],
  isProductFavorite: () => {
    return false;
  },
  toggleFavoriteItem: () => {
    return;
  },
};

export const FavoritesContext =
  createContext<FavoritesContextValue>(defaultValue);

export interface FavoritesProviderProps {
  children?: React.ReactNode;
  settings: ApiSettings;
}

export const FavoritesProvider: React.FC<FavoritesProviderProps> = ({
  settings,
  children,
}) => {
  const KEY = 'snickers_favorites_v2';
  const favorites = useLocalStorageValue<SolidgearProduct[]>(
    `${KEY}-${settings?.market || 'com'}`,
    { defaultValue: [], initializeWithValue: false },
  );

  const toggleFavoriteItem = (
    product: SolidgearProduct,
    isFavorite: boolean,
  ) => {
    const result = toggleFavorite({
      isFavorite,
      items: favorites.value || [],
      product,
    });
    favorites.set(result);
  };

  const deleteFavorites = () => {
    favorites.set([]);
  };

  const isProductFavorite = (sku: string): boolean => {
    return favorites?.value?.find((x) => x.sku === sku) ? true : false;
  };

  return (
    <FavoritesContext.Provider
      value={{
        deleteFavorites,
        favorites: favorites.value || [],
        isProductFavorite,
        toggleFavoriteItem,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};
