import { SolidgearProduct } from '@hultafors/solidgear/types';

export const transformProduct = (product: SolidgearProduct) => {
  return {
    category: product.category,
    id: product.id,
    image: product.image,
    name: product.name,
    parentCategory: product.parentCategory,
    price: product.price,
    productFeature: product.productFeature,
    safetyClass: product.safetyClass,
    sku: product.sku,
    usp: product.usp,
  };
};

/**
 * @desc Toggles a favorite product. Product is transformed to only needed properties.
 * @param {object} product - product representation from list view or detail page
 * @param {boolean} isFavorite - true would indicate product is already a favorite
 * @return {array} favorites
 */
export const toggleFavorite = ({
  items,
  product,
  isFavorite,
}: {
  items: SolidgearProduct[];
  product: SolidgearProduct;
  isFavorite: boolean;
}): SolidgearProduct[] => {
  if (items) {
    if (isFavorite) {
      return removeFromFavorites(items, product);
    } else {
      return addToFavorites(items, product);
    }
  } else {
    return addToFavorites(items, product);
  }
};

const addToFavorites = (
  items: SolidgearProduct[],
  product: any,
): SolidgearProduct[] => {
  if (!product) {
    return items ? [...items] : [];
  }

  if (items) {
    const exist = items.find((x) => x.sku === product.sku);
    if (!exist) {
      const transformedProduct = transformProduct(product) as SolidgearProduct;
      if (transformedProduct) {
        return [...items, transformedProduct];
      }
    } else {
      return [...items];
    }
  } else {
    // Add first item in favorites
    const transformedProduct = transformProduct(product) as SolidgearProduct;
    if (transformedProduct) {
      return [transformedProduct];
    }
  }

  return [];
};

/**
 * @desc Takes array of products presumably from local storage. Removes second param product from array and return it
 * @private Public for tests only
 * @param {array} items
 * @param {object} product
 * @returns {array} cart items
 */
export const removeFromFavorites = (
  items: SolidgearProduct[],
  product: SolidgearProduct,
): SolidgearProduct[] => {
  return items
    ? product?.sku
      ? items.filter((x) => x.sku !== product.sku)
      : [...items]
    : [];
};

/**
 * @desc Checks id product is in favorites list ( local storage )
 * @param string sku - product sku
 * @param array items - list of favorites
 * @return boolean
 */
export const isProductFavorite = (
  sku: string,
  items: SolidgearProduct[],
): boolean => (items?.find((x) => x.sku === sku) ? true : false);
